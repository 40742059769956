import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./composablenow.scss";
import "./resources/resources.scss";
import {
  getPage,
  TypeComposablenowEventPage,
  TypeComposablenowEventQueryResult,
} from "../utils/queries/composablenow_event";
import {
  PageHeaderCustom,
  PageHeaderStandard,
} from "../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../components/title/title";
import AmazeeIoMetadata from "../components/metadata";
import RichTextRender from "../utils/rich-text";
import AmazeePage from "../components/layout";
import linkResolver from "../utils/linkResolver";
import usePreviewData from "../utils/usePreviewData";
import { ResourceInteruptionWide } from "../templates/resources/customers";
import { TypeHomepageV2Quote } from "../utils/queries/homepagev2";
import { Resource } from "../utils/queries/resources";
import { StandardCard } from "../templates/resources/cards";

const ComposableNOWPage: React.FC<{ pageContext: any; location: any }> = ({
  pageContext,
  location,
}) => {
  const result = usePreviewData(
    pageContext.data,
    "prismicComposablenow",
    true
  ) as TypeComposablenowEventPage;

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="composable-event">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustom}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <div id="anchor-nav">
          <div className="container ">
            <div className="flex">
              <div className="col-12 col-md-10">
                <div className="row" dangerouslySetInnerHTML={{__html: result.event_menu }} />
              </div>
            </div>
          </div>
        </div>

        <section id="about">
          <div className="anchor-point" id="about-section"></div>
          <div className="container ">
            <div className="row flex">
              <div className="col-12 col-lg-6">
                {RichTextRender(result.about_text)}
              </div>
              <div className="col-12 col-lg-6 text-center">
                <span>- Sponsors -</span>
                {result.sponsors.map((sponsor, index) => (
                  <div className="sponsor block col-8 col-md-4 mx-auto mb-[4.375rem]">
                    <img
                      src={sponsor.sponsor_logo.url}
                      alt={sponsor.sponsor_logo.alt}
                    />
                  </div>
                ))}
                <div className="sponsor block col-8 col-md-4 mx-auto mb-[4.375rem]"><a href="https://www.thedroptimes.com/" target="_blank"><img src="https://amazeeio.cdn.prismic.io/amazeeio/ZhV1bDjCgu4jztbq_The-Drop-Times-Logo.svg?auto=compress%2Cformat" /></a></div>
              </div>
            </div>
          </div>
        </section>

        <section id="schedule">
          <div className="anchor-point" id="schedule-section"></div>
          <div className="container ">
            <div className="row flex">
              <div className="col-12 col-lg-6">
                {RichTextRender(result.schedule_text)}
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={result.schedule_image.url}
                  alt={result.schedule_image.alt}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="speaker-linup">
          <div className="anchor-point" id="speaker-lineup-section"></div>
          <div className="resource-cta-wrapper no-mb">
            <div className="resource-cta">
              <div className="row flex justify-center"> 
                <div className="col-12 col-md-12 flex justify-center flex-col items-center">
                  <div className="text-white text-center">Session Schedule</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-center">
            {result.session_breaks.map((breakItem, breakIndex) =>
            <>

              <div className={`flex breakItem order-${breakItem.after_session_number}`} key={breakIndex} >
                {RichTextRender(breakItem.break_name.text)}
              </div>
                      </>
                    )}

              {result.speaker_lineup.map((lineupItem, index) => (
                <>
                      <div
                        key={index}
                        className={`col-12 resource-card-link order-${index}`}
                      >
                        <div className="resource-card cursor-none hover:none relative h-full">
                          <div
                            className="block-wrap h-full"
                            // style={{ height: 595 }}
                          >
                            <div className="img-wrap">
                              <img
                                src={lineupItem.session_teaser_image.url}
                                alt={lineupItem.session_teaser_image.alt}
                                className="resource-image w-full h-full object-cover object-center"
                                loading="lazy"
                              />
                            </div>
                            <div className="content">
                              <div className="content-type">
                                {RichTextRender(
                                  lineupItem.session_pre_title.text
                                )}
                              </div>
                              <div className="h-full">
                                <div className="content-title">
                                  {RichTextRender(
                                    lineupItem.session_title.text
                                  )}
                                </div>
                                <div className="content-description line-clamp">
                                  {RichTextRender(
                                    lineupItem.session_description
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                </>
              ))}
            </div>
          </div>
        </section>

        { result.cta_text && 
        <ResourceInteruptionWide
          cta_text={result.cta_text}
          cta_button_text={result.cta_button_text}
          cta_link={result.cta_link}
          topMargin={false}
        />
      }

        <section id="venue">
          <div className="anchor-point" id="venue-section"></div>
          <div className="container col-lg-6 lg:text-center">
            <div className="mx-auto mb-8">
              {RichTextRender(result.venue_text)}
            </div>
            <img
              className="mx-auto venue-img"
              src={result.venue_image.url}
              alt={result.venue_image.alt}
            />
          </div>
        </section>

        <section id="faq">
          <div className="anchor-point" id="faq-section"></div>
          <div className="container ">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6">
                {RichTextRender(result.faq_text)}
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

export default ComposableNOWPage;
